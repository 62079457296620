<template>
  <v-navigation-drawer
    v-model="show"
    absolute
    color="white"
    class="sideBar"
    hide-overlay
  >
    <v-card class="mt-8 same-padding-appbar" tile elevation="0">
      <v-row class="mt-12 mt-xl-12">
        <!-- filtros de projects -->
        <template v-if="changeContent == 1">
          <v-col>
            <div class="sideBar__max-width">
              <div>
                <v-btn
                  text
                  width="100%"
                  class="btn-filter-category"
                  v-bind:ripple="false"
                  v-for="(category, cat) in categoriesProjects"
                  v-bind:key="cat"
                  @click="updatingFilter(category.name), goToProjects()"
                >
                  <v-icon
                    :color="
                      filter == category.name ||
                      $route.params.filter == category.name
                        ? '#FFF171'
                        : '#ECECEC'
                    "
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span
                    class="titles-card-projects not-text-transform pl-3 letter-spacing-0"
                    >{{ firstLetterUppercase(category.name) }}</span
                  >
                </v-btn>
              </div>

              <div class="mt-8">
                <span
                  class="titles-card-projects not-text-transform d-block mb-5"
                  >Elegir espacio ocupado en m<sup>2</sup></span
                >

                <template v-if="rangeSetted">
                  <v-range-slider
                    v-model="range"
                    v-bind:error="false"
                    hide-details
                    v-bind:min="minValue"
                    v-bind:max="maxValue"
                    dense
                    step="1"
                    color="#000000"
                    track-color="#000000"
                    @end="setRange()"
                  ></v-range-slider>
                </template>

                <ul class="d-flex pl-0 list-style-none justify-space-between">
                  <li>
                    <span>{{ range[0] }}m<sup>2</sup></span>
                  </li>
                  <li>
                    <span>{{ range[1] }}m<sup>2</sup></span>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
        </template>
        <!-- /filtros de projects -->

        <!-- filtros de news -->
        <template v-else-if="changeContent == 2">
          <v-col>
            <div>
              <v-btn
                text
                width="100%"
                class="btn-filter-category"
                v-bind:ripple="false"
                v-for="(category, cat) in categoriesNews"
                v-bind:key="cat"
                @click="
                  updatingFilterNews(category.name), $router.push('/news')
                "
              >
                <v-icon
                  :color="
                    filterNewName == category.name ||
                    $route.params.filter == category.name
                      ? '#FFF171'
                      : '#ECECEC'
                  "
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span
                  class="titles-card-projects not-text-transform pl-3 letter-spacing-0"
                  >{{ firstLetterUppercase(category.name) }}</span
                >
              </v-btn>
            </div>
          </v-col>
        </template>
        <!-- /filtros de news -->

        <template v-if="$vuetify.breakpoint.mdAndDown">
          <v-col cols="12">
            <span
              class="titles-card-projects d-block mt-5 btn-close"
              style="width: fit-content"
              @click="show = false"
              >Cerrar</span
            >
          
          </v-col>
        </template>
          <span class="d-none">{{ setRangeC }}</span>
      </v-row>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    open: { type: Boolean, required: true },
    changeContent: { type: Number, required: true },
    projects: { type: Array, required: false },
  },
  computed: {
    setRangeC: function () {
      if (this.changeContent == 1) {
        setTimeout(() => {
          var min = 0;
          for (let index = 0; index < this.projects.length; index++) {
            const element = this.projects[index];
            // if(index == 0){
            //   min = element.meters;
            // }
            min = Math.min(element.meters);

            if (parseInt(element.meters) < parseInt(min)) {
              min = element.meters;
            }

            min = 0;
          }

          this.minValue = min;

          var max = 0;
          for (let index = 0; index < this.projects.length; index++) {
            const element = this.projects[index];
            if (index == 0) {
              max = element.meters;
            }

            if (parseInt(element.meters) > parseInt(max)) {
              max = element.meters;
            }

            console.log(element.meters)
          }

          this.maxValue = max;

          this.range[0] = min;
          this.range[1] = max;

          // this.newRange[0] = min;
          // this.newRange[1] = max;

          this.rangeSetted = true;

          this.setRange();
        }, 1000);
      }
    },
    show: {
      get() {
        return this.open;
      },
      set(value) {
        if (!value) {
          return this.$emit("close");
        }
      },
    },
    categoriesProjectsF: function () {
      var categories = this.categoriesProjects;

      return categories;
    },
    categoriesNewsF: function () {
      var categories = this.categoriesNews;

      return categories;
    },
  },
  data() {
    return {
      categoriesProjects: [],
      filter: "",
      range: [100, 6000],
      newRange: [0, 0],
      minValue: 0,
      maxValue: 0,
      //categories news
      categoriesNews: [],
      filterNewName: "",
      counter: 0,
      rangeSetted: false,
    };
  },
  beforeCreate() {
    //fetch for categorie´s projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/categoriesProject?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesProjects = res.entries))
      .then(() => {
        this.sendCategoriesProjects();
      })
      .catch((error) => console.log(error.message));

    //fetch for categorie´s news
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/newCategories?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesNews = res.entries))
      .then(() => {
        this.sendCategoriesNews();
      })
      .catch((error) => console.log(error.message));
  },
  methods: {
    firstLetterUppercase: function (text) {
      //global method
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    setRange: function (e) {
      this.$emit("rangeUpdating", this.range);
    },
    updatingFilter: function (categoryName) {
      if (this.filter == categoryName) {
        this.filter = "";
      } else {
        this.filter = categoryName;
      }

      // console.log(this.filter);
      this.$emit("updatingFilters", this.filter);
    },
    sendCategoriesProjects: function () {
      this.$emit("updatingCategories", this.categoriesProjectsF);
    }, //end methods for projects
    sendCategoriesNews: function () {
      this.$emit("updatingCategories", this.categoriesNewsF);
    },
    updatingFilterNews: function (categoryName) {
      if (this.filterNewName == categoryName) {
        this.filterNewName = "";
      } else {
        this.filterNewName = categoryName;
      }

      this.$emit("updatingFilterNews", this.filterNewName);
    },
    goToProjects: function () {
      if (this.$route.params.filter != null) {
        this.$router.push("/projects");
      }
    },
  },
};
</script>